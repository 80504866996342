import React from 'react';
import {Link} from "react-router-dom";
import store from "store";

import {s_a, country_arr} from "../assets/countries";
import aboutdata from "../assets/aboutdata";

import NProgress from 'nprogress';
import Noty from 'noty';
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';

import HttpService from "../httpservice";

const httpservice = new HttpService();

const Utilities = {
    Notify: function(msg, type, done) {//alert, success, warning, error, info/information
        new Noty({
            type: type,
            layout: 'topRight',
            theme: 'nest',
            text: msg,
            timeout: '5000',
            progressBar: true,
            closeWith: ['click'],
            killer: true,
            callbacks: {
                beforeShow: function() {
                    // console.log('beforeend', 'Preparing... ⏱<br/>');
                },
                onShow: function() {
                    // console.log('beforeend', 'Showed ✨<br/>');
                },
                onHover: function() {
                    // console.log('beforeend', 'Hovered 👀<br/>');
                },
                onClick: function() {
                    // console.log('beforeend', 'Clicked ✅<br/>');
                },
                onClose: function() {
                    // console.log('beforeend', 'Bye 👋🏻<br/>');
                    if(done) return done.call();
                }
            },
        }).show();
    },
};

const percentage = 66;

class Home extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
            activetab: "DONATE",
		    validate: false,
            activetext: aboutdata.vision,
            activetexttitle: "Our Vision",

            donoremail: "",
            amount: "",
            donationtype: "",
            amounttype: "",

            teamfullname: "",
            teamemail: "",
            teamphone: "",
            teamcountry: "",
            teamstate: "",
            teamcategory: "",
            teamintroduction: "",

            partnerfullname: "",
            partneremail: "",
            partnerphone: "",
            partnercountry: "",
            partnerstate: "",
            partnerspace: "",

            recyclefullname: "",
            recycleemail: "",
            recyclephone: "",
            recyclecountry: "",
            recyclestate: "",

            supportname: "",
            supportemail: "",
            supportphone: "",
            supportmessage: ""
        };
	};

	clearInput = () => {
	    this.setState({
            validate: false,
            teamfullname: "",
            teamemail: "",
            teamphone: "",
            teamcountry: "",
            teamstate: "",
            teamcategory: "",
            teamintroduction: "",

            partnerfullname: "",
            partneremail: "",
            partnerphone: "",
            partnercountry: "",
            partnerstate: "",
            partnerspace: "",

            recyclefullname: "",
            recycleemail: "",
            recyclephone: "",
            recyclecountry: "",
            recyclestate: "",

            donoremail: "",
            amount: "",
            donationtype: "",
            amounttype: "",

            supportname: "",
            supportemail: "",
            supportphone: "",
            supportmessage: ""
        })
    };

    determinePageScroll = () => {

        if (document.body.scrollTop > 100) {
            document.getElementById("mainNav").classList.add("givebg");
        } else {
            document.getElementById("mainNav").classList.remove("givebg");
        }
    };

    print_country = (country_class) => {
        // given the class of the <select> tag as function argument, it inserts <option> tags
        var option_str = document.getElementsByClassName(country_class);

        for(var i=0; i<option_str.length; i++) {
            option_str[i].length=0;
            option_str[i].options[0] = new Option('Select Country','');
            option_str[i].selectedIndex = 0;
            for (var j=0; j<country_arr.length; j++) {
                option_str[i].options[option_str[i].length] = new Option(country_arr[j],country_arr[j]);
            }
        }
    };

    switchTabs = (activetab) => {
        this.setState({activetab: activetab});
        store.set("activetab", activetab);

        document.getElementById("formfields").scrollIntoView({behavior: "smooth", block: "nearest"});
    };

    scrollToPage = (page) => {
        document.getElementById(page).scrollIntoView({behavior: "smooth", block: "nearest"});
    };

    handleAboutUsLinks = (key, title, page) => {
        document.getElementById(page).scrollIntoView({behavior: "smooth", block: "nearest"});

        this.toggleActiveText(key, title)
    };

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleRadio = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    print_state = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

        let state_index = country_arr.indexOf(e.target.value);

        let target = e.target.name.substring(0, e.target.name.length-7)+"state"

        var option_str = document.getElementById(target);

        option_str.length=0;	// Fixed by Julian Woods
        option_str.options[0] = new Option('Select State','');
        option_str.selectedIndex = 0;
        var state_arr = s_a[state_index+1].split("|");
        for (var i=0; i<state_arr.length; i++) {
            option_str.options[option_str.length] = new Option(state_arr[i],state_arr[i]);
        }
    };

    handleSupport = () => {
        if(
            !this.state.supportname
            || !this.state.supportemail
            || !this.state.supportphone
            || !this.state.supportmessage
        ) {
            this.setState({validate: true});
        }
        else {
            this.setState({validate: false});

            let data = {
                email: this.state.supportemail,
                phone: this.state.supportphone,
                fullname: this.state.supportname,
                message: this.state.supportmessage
            }

            NProgress.inc();

            httpservice.postservice("/api/support", data)
                .then((response) => {
                    NProgress.done();

                    if(response.status === 200) {
                        this.clearInput();
                        Utilities.Notify("Your message has been recorded. We'd get back to you shortly.", "success");
                    }
                    console.log(response)
                })
                .catch((error) => {
                    NProgress.done();

                    Utilities.Notify("Unable to complete your request. Please try again later.", "error");
                    console.log("err: ", error)
                })
        }
    };

    handleNewDonor = () => {
        
        if(!this.state.donationtype || !this.state.amount || !Number(this.state.amount || !this.state.donoremail)) {
            this.setState({validate: true});
        }
        
        else {
            this.setState({validate: false});

            let data = {
                email: this.state.donoremail,
                amount: this.state.amount,
                donationType: this.state.donationtype
            }

            NProgress.inc();

            httpservice.postservice("/api/donor", data)
                .then((response) => {
                    NProgress.done();

                    if(response.status === 200) {

                        let paymentobj = {
                            email: this.state.donoremail,
                            amount: this.state.amount,
                            ref: response.data.paymentRef
                        }

                        this.payWithPaystack(paymentobj);
                    }
                })
                .catch((error) => {
                    NProgress.done();
                    
                    Utilities.Notify("Unable to complete your request. Please try again later.", "error");
                    console.log("err: ", error)
                })
        }
    };

    payWithPaystack = (paymentobj) => {

        var config = {

            key: 'pk_live_41e875bbcbafa28a3e8d07a2232d087911bbdb03', // Replace with your public key

            email: paymentobj.email,

            amount: paymentobj.amount * 100,

            currency: "NGN", //GHS for Ghana Cedis

            ref: paymentobj.ref,

            onClose: function(e) {

                console.log('Window closed.');

            },

            onclose: function() {
                console.log('Window closed2.');
            },

            callback: function(response){
                Utilities.Notify("Payment successful.", "success");

                this.clearInput();

            }.bind(this)
        };

        var paystackPopup = window.PaystackPop.setup(config);

        paystackPopup.openIframe();
    }

    handleNewTeam = () => {

        if(
            !this.state.teamfullname
            || !this.state.teamemail
            || !this.state.teamphone
            || !this.state.teamcategory
            || !this.state.teamcountry
            || !this.state.teamstate
            || !this.state.teamintroduction
        ) {
            this.setState({validate: true});
        }
        else {
            NProgress.inc();
            this.setState({validate: false});

            let data = {
                fullname: this.state.teamfullname,
                email: this.state.teamemail,
                phone: this.state.teamphone,
                country: this.state.teamcountry,
                state: this.state.teamstate,
                introduction: this.state.teamintroduction,
                team: this.state.teamcategory
            }

            httpservice.postservice("/api/team", data)
                .then((response) => {
                    NProgress.done();

                    if(response.status === 200) {
                        this.clearInput();
                        Utilities.Notify("Registration successful.", "success");
                    }
                })
                .catch((error) => {
                    NProgress.done();
                    Utilities.Notify("Unable to complete your request. Please try again later.", "error");
                    console.log("err: ", error)
                })
        }
    };

    handleNewPartner = () => {
        if(
            !this.state.partnerfullname
            || !this.state.partneremail
            || !this.state.partnerphone
            || !this.state.partnercountry
            || !this.state.partnerstate
            || !this.state.partnerspace
        ) {
            this.setState({validate: true});
        }
        else {
            this.setState({validate: false});
            NProgress.inc();

            let data = {
                fullname: this.state.partnerfullname,
                email: this.state.partneremail,
                phone: this.state.partnerphone,
                country: this.state.partnercountry,
                state: this.state.partnerstate,
                partnershipspace: this.state.partnerspace
            }

            httpservice.postservice("/api/partner", data)
                .then((response) => {
                    NProgress.done();

                    if(response.status === 200) {
                        this.clearInput();
                        Utilities.Notify("Registration successful.", "success");
                    }
                    console.log(response)
                })
                .catch((error) => {
                    NProgress.done();
                    Utilities.Notify("Unable to complete your request. Please try again later.", "error");
                    console.log("err: ", error)
                })
        }
    };

    handleNewRecycler = () => {
        if(
            !this.state.recyclefullname
            || !this.state.recycleemail
            || !this.state.recyclephone
            || !this.state.recyclecountry
            || !this.state.recyclestate
        ) {
            this.setState({validate: true});
        }
        else {
            NProgress.inc();
            this.setState({validate: false});

            let data = {
                fullname: this.state.recyclefullname,
                email: this.state.recycleemail,
                phone: this.state.recyclephone,
                country: this.state.recyclecountry,
                state: this.state.recyclestate,
            }

            httpservice.postservice("/api/recycle", data)
                .then((response) => {
                    NProgress.done();

                    if(response.status === 200) {
                        this.clearInput();
                        Utilities.Notify("Registration successful.", "success");
                    }
                    console.log(response)
                })
                .catch((error) => {
                    NProgress.done();

                    Utilities.Notify("Unable to complete your request. Please try again later.", "error");
                    console.log("err: ", error)
                })
        }
    };

    toggleActiveText = (activetext, title) => {

        this.setState({
            activetext: aboutdata[activetext],
            activetexttitle: title
        });
    };

    setCountDown = () => {
        var countDownDate = new Date("Jun 10, 2021 15:37:25").getTime();

        var x = setInterval(function() {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            // var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            // var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            console.log("seconds: ", seconds);

            // // Output the result in an element with id="demo"
            // let clock = document.getElementById("clock");

            // console.log()

            // if(clock) {
            //     clock.innerHTML = days + "days " + hours + "hrs " + minutes + "mins " + seconds + "secs ";
            // }

            // If the count down is over, write some text
            if (distance < 0) {
                clearInterval(x);
                document.getElementById("clock").innerHTML = "EXPIRED";
            }
        }, 1000);
    }

    render() {
		return (
			<article className="animated fadeIn delay-0.5s" onScroll={this.determinePageScroll}>
                <nav className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
                    <div className="container-fluid">

                        <Link className="navbar-brand js-scroll-trigger goldcolor bavistafont f30px" to="/">Book Bunker</Link>

                        <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                                data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                                aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse row" id="navbarResponsive">

                            {/* <div className="col-lg navbar-nav navlnks dntebtn">
                                <a className="btn btn-lg js-scroll-trigger goldcolor" href="#donate" style={{background: "#BF2025"}}>Donate</a>
                            </div> */}

                            <ul className="col-lg navbar-nav my-2 my-lg-0 navlnks ml-4">
                                <li className="nav-item">
                                    <a className="lh23 mt-2 nav-link js-scroll-trigger redcolor varchecapsfont dntbtn tshw cp" onClick={() => {this.switchTabs("DONATE")}} style={{borderBottom: "2px solid #BF2025"}}>Donate</a></li>                                

                                <li className="nav-item dropdown">
                                    <a class="lh23 mt-2 nav-link js-scroll-trigger goldcolor varchecapsfont cp dropdown-toggle tshw" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Get Involved
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a className="dropdown-item cp" onClick={() => {this.switchTabs("DONATE")}}>Donate</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item cp" onClick={() => {this.switchTabs("JOIN")}}>Join a team</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item cp" onClick={() => {this.switchTabs("PARTNER")}}>Partner with us</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item cp" onClick={() => {this.switchTabs("RECYCLE")}}>Recycle your old books</a>
                                    </div>
                                </li>

                                <li className="nav-item dropdown">
                                    <a class="tshw lh23 mt-2 nav-link js-scroll-trigger goldcolor varchecapsfont cp dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        About
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a className="dropdown-item cp" onClick={() => {this.scrollToPage("aboutus")}}>About Us</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item cp" onClick={() => {this.scrollToPage("faq")}}>FAQs</a>
                                        {/* <a className="dropdown-item" href="#">Our Mission</a>
                                        <div className="dropdown-divider"></div>

                                        <a className="dropdown-item" href="#">Our Focus</a>
                                        <a className="dropdown-item" href="#">Our Values</a>
                                        <div className="dropdown-divider"></div>

                                        <a className="dropdown-item" href="#">What we do</a>
                                        <a className="dropdown-item" href="#">Our Story</a> */}
                                    </div>
                                </li>

                                <li className="nav-item">
                                    <a className="tshw lh23 mt-2 nav-link js-scroll-trigger goldcolor varchecapsfont cp" onClick={() => {this.scrollToPage("whatwedo")}}>
                                        What we do
                                    </a>
                                </li>
                                
                                <li className="nav-item"><a className="tshw lh23 mt-2 nav-link js-scroll-trigger goldcolor varchecapsfont cp" onClick={() => {this.scrollToPage("contact")}}>Contact</a></li>
                            </ul>
                        </div>

                    </div>
                </nav>
                
                <header className="masthead" onScroll={this.determinePageScroll}>
                    <div className="container h-100">

                        <div className="row h-100 align-items-center justify-content-center text-center">

                            <div className="col-lg-10 align-self-end varchefont">
                                <h1 className="text-uppercase orangecolor font-weight-bold">Planting Libraries.</h1>
                                <h1 className="text-uppercase orangecolor font-weight-bold">Nurturing Dreams.</h1>
                            </div>

                            <div className="col-lg-12 align-self-baseline">
                                <div className="row mx-md-n5">
                                    <div className="col px-md-5"><div className="p-3"/></div>
                                </div>

                                <div className="row mx-md-n5">
                                    <div className="col px-md-5"><div className="p-3"/></div>
                                </div>

                                <p className="text-white-75 font-weight-bold lorafont" style={{textShadow: "rgb(0 0 0) 0px 0px 10px"}}>
                                    1 in 5 of all out-of-school children in the world are in Nigeria. 
                                    That is, 13. 2 million children denied an education. 
                                    Meanwhile, public schools remain overwhelmed and underfunded. 
                                    The Book Bunker Project is a mission to build and support a network of community libraries in low-income areas. 
                                    We believe books can change the trajectory of a life.
                                </p>

                                <p className="text-white-75 font-weight-bold mb-3 lorafont" style={{textShadow: "rgb(0 0 0) 0px 0px 10px"}}>
                                    Choose to be a hero/ine in someone’s tale.
                                </p>

                                <div className="row justify-content-center mt-5 hideformobile">
                                    <div className="col-lg-3 text-right"><a onClick={() => {this.switchTabs("DONATE")}} className="donatebtnleft">Donate Now</a></div>
                                    <div className="col-lg-3 text-left"><a onClick={() => {this.scrollToPage("whatwedo")}} className="knowmorebtnright">Know More</a></div>
                                </div>

                            </div>
                        </div>

                    </div>
                </header>
                
                <section className="" id="about">
                    <div className="container-fluid">

                        <div className="row milkgraybg pagecontainer">
                            <div className="col-lg-4">
                                <img 
                                    src="/assets/img/bgds/bbfront.png" 
                                    alt="Child reading" 
                                    className="w-100"
                                />
                            </div>

                            <div className="col-lg-8 lorafont">
                                <div className="display-5 redcolor">Our mission is to link all communities to a fully-functional library.</div>
                                <hr />
                                <div className="display-5 bluecolor">There are 13.2 million children out-of-school in Nigeria. We build libraries in villages and low-income urban areas to foster literacy.</div>

                                <div className="text-center mt-5">
                                    <button className="btn btn-outline-secondary bluecolor btnborder varchefont" onClick={() => {this.switchTabs("DONATE")}}>Support our mission</button>
                                </div>

                            </div>
                        </div>

                        <div className="row pagecontainer">
                            <div className="col-lg">
                                <img 
                                    src="/assets/img/bgds/infinitycropped.png" 
                                    alt="Infinity Image" 
                                    className="infinityimg"
                                />
                            </div>
                            
                            <div className="col-lg display-5">
                                <div className="greencolor varchefont">Innovation</div>
                                <hr style={{width: "30%", margin: "1rem 0"}} />

                                <div className="display-5 bluecolor lorafont">
                                    We value creativity and people. We seek to implement novel solutions leveraging on our diverse network of volunteers and partners.
                                </div>

                                <div className="my-3 joinbtn"><button className="btn btn-outline-secondary bluecolor btnborder varchefont" onClick={() => {this.switchTabs("JOIN")}}>Join Us</button></div>
                            </div>

                            <div className="col-lg-5 row">
                                <div className="col-lg mb-2">
                                    <img 
                                        src="/assets/img/bgds/oldwoman.png" 
                                        alt="Old woman" 
                                        className="w-100"
                                    />
                                </div>

                                <div className="col-lg">
                                    
                                    <div className="mb-3">
                                        <button className="btn btn-outline-secondary btnborder bluecolor varchefont" onClick={() => {this.switchTabs("RECYCLE")}}>Give your books</button>
                                    </div>
                                    <div className="display-5 text-white lorafont">
                                        <p>Up-coming Book Drives</p>
                                        <p>Abuja: March 1 - April 30, 2021 </p>
                                        <p>Lagos:  May 1 – June 30, 2021</p>
                                    </div>

                                    <div className="bluebgsection"></div>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>
                
                <div className="otherpages commonbg">

                    <section className="page-section" id="services">
                        <div className="container-fluid">
                            <div className="">
                                <div className="row">

                                    <div className="col-lg-7 shadow bg-white projectcardcontainer">
                                        <div className="text-center display-6 redcolor pb-3 varchefont">Ongoing project card</div>

                                        <div className="pb-4 lorafont"> 
                                            <div className="">
                                                Location:
                                            </div>

                                            <div className="bluercolor">
                                                Amaba, Isuikwuato Local Government Area, Abia State, Nigeria.
                                            </div>
                                        </div>

                                        <div className="pb-4 lorafont"> 
                                            <div className="">
                                                Population:
                                            </div>

                                            <div className="bluercolor">
                                                50,000 (Est.)
                                            </div>
                                        </div>
                                        
                                        <div className="pb-4 lorafont"> 
                                            <div className="">
                                                Population (below 15 years):
                                            </div>

                                            <div className="bluercolor">
                                                21,255 (Est.)
                                            </div>
                                        </div>

                                        <div className="pb-4 lorafont"> 
                                            <div className="">
                                                Secondary schools:
                                            </div>

                                            <div className="bluercolor">
                                                16
                                            </div>
                                        </div>

                                        <div className="pb-4 lorafont"> 
                                            <div className="">
                                                Primary schools:
                                            </div>

                                            <div className="bluercolor">
                                                40
                                            </div>
                                        </div>

                                        <div className="pb-4 lorafont"> 
                                            <div className="">
                                                Children formally enrolled in primary and secondary schools:
                                            </div>

                                            <div className="bluercolor">
                                                8,042 pupils
                                            </div>
                                        </div>

                                        <div className="pb-4 lorafont"> 
                                            <div className="">
                                                Potential impact at 70% utilization rate:
                                            </div>

                                            <div className="bluercolor">
                                            14,879 children with access to books. 
                                            When youths are included the number rises to 21,235
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-lg lorafont">
                            
                                        <div id="prjctqoute" className=" display-6 bluecolor projectsquotetext">
                                            “Today a reader, <br /> tomorrow a leader.”
                                            <div>– Margaret Fuller </div>
                                        </div>

                                        <div className="quotecircle"></div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                    
                    <div className="page-section" id="portfolio">
                        <div className="container-fluid p-0">
                            <div className="row pagecontainer">

                                <div className="col-lg-5 bg-white shadow py-3">
                                    <div id="projectimgs" className="carousel slide crsel" data-ride="carousel">

                                        <ul className="carousel-indicators">
                                            <li data-target="#projectimgs" data-slide-to="0" className="active"></li>
                                            <li data-target="#projectimgs" data-slide-to="1"></li>
                                            <li data-target="#projectimgs" data-slide-to="2"></li>
                                        </ul>

                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <img src="/assets/img/bgds/erosion.jpeg" alt="Los Angeles" className="w-100 h-100" />
                                            </div>
                                            <div className="carousel-item">
                                                <img src="/assets/img/bgds/abiacutoff.jpeg" alt="Chicago" className="w-100 h-100" />
                                            </div>
                                            <div className="carousel-item">
                                                <img src="/assets/img/bgds/barrowpusher.jpeg" alt="New York" className="w-100 h-100" />
                                            </div>
                                        </div>

                                        <a className="carousel-control-prev" href="#projectimgs" data-slide="prev">
                                            <span className="carousel-control-prev-icon"></span>
                                        </a>
                                        <a className="carousel-control-next" href="#projectimgs" data-slide="next">
                                            <span className="carousel-control-next-icon"></span>
                                        </a>

                                    </div>

                                    <div className="py-2 lorafont">
                                        <span>The road to Isuikwuato. The road collapsed due to heavy rains in 2018 and have remained unfixed till date.</span>
                                    </div>
                                </div>

                                <div className="col-lg bg-white shadow py-3 px-4">
                                    <h3 className="text-center varchefont">Project Story</h3>

                                    <p className="display-5 lorafont">
                                        <span className="display-2">T</span>he Isuikwuato project is Book Bunker’s pilot location. Book Bunker was inspired by the plea of a young village girl  for books. 
                                        The young girl who inspired us, Oluchi, resides in Isuikwuato. 
                                    </p>

                                    <p className="display-5 lorafont">
                                        Isuikwuato is located in South-Eastern Nigeria. Within the region, there are large pockets of underdeveloped localities, commonly called villages. 
                                        In these villages, due to a high rate of poverty, many children drop out of school to help their parents in trade or household chores.
                                    </p>

                                    <p className="display-5 lorafont">
                                    Oluchi alongside thousands of children in the community are still without books. This is exacerbated by schools shutting down due to Covid-19 fears.
                                    </p>

                                    <p className="display-5 lorafont">
                                        We believe that, together, we can make her wish for books come true; 
                                        not only for her but for the millions of kids in other villages who also plead to unhearing ears for an education and the chance at a future. 
                                        Join us on this journey.
                                    </p>

                                    <div className="text-center"><button className="btn btn-outline-secondary redcolor btnborder varchefont" onClick={() => {this.switchTabs("DONATE")}}>Donate</button></div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <section className="page-section giveheight" id="formfields">
                        <div className="container-fluid pagecontainer">
                            <div className="row">
                                <div className="form w-100 justify-content-center mt-5">

                                    <ul className="nav nav-tabs w-100 text-center" id="myTab" role="tablist">
                                        <li className="nav-item hometab">
                                            <a
                                                onClick={() => {this.switchTabs("DONATE")}}
                                                className={this.state.activetab === "DONATE" ? "cp nav-link active text-dark varchefont" : "cp nav-link hide text-dark varchefont"}
                                                id="profile-tab" data-toggle="tab" 
                                                role="tab" 
                                                aria-controls="donate" 
                                                aria-selected="false">
                                                Donate
                                            </a>
                                        </li>
                                        <li className="nav-item hometab">
                                            <a
                                                onClick={() => {this.switchTabs("JOIN")}}
                                                className={this.state.activetab === "JOIN" ? "cp nav-link active text-dark varchefont" : "cp nav-link hide text-dark varchefont"}
                                                id="profile-tab" 
                                                data-toggle="tab" 
                                                role="tab" 
                                                aria-controls="profile" 
                                                aria-selected="false">Join<span 
                                                className="shortenname"> a book bunker team</span>
                                            </a>
                                        </li>
                                        <li className="nav-item hometab">
                                            <a 
                                                onClick={() => {this.switchTabs("PARTNER")}}
                                                className={this.state.activetab === "PARTNER" ? "cp nav-link active text-dark varchefont" : "cp nav-link hide text-dark varchefont"}
                                                id="contact-tab" data-toggle="tab"  
                                                role="tab" aria-controls="contact" aria-selected="false">
                                                Partner<span className="shortenname"> with us</span>
                                            </a>
                                        </li>
                                        <li className="nav-item hometab">
                                            <a 
                                                onClick={() => {this.switchTabs("RECYCLE")}}
                                                className={this.state.activetab === "RECYCLE" ? "cp nav-link active text-dark varchefont" : "cp nav-link hide text-dark varchefont"}
                                                id="recycle-tab" data-toggle="tab" 
                                                role="tab" aria-controls="recycle" aria-selected="false">
                                                Recycle<span className="shortenname"> your old books</span></a>
                                        </li>
                                    </ul>

                                    <div className="tab-content w-100" id="myTabContent">

                                        <div 
                                            className={this.state.activetab === "DONATE" ? " tab-pane show active fade" : "tab-pane fade"} 
                                            id="donate" role="tabpanel"
                                            aria-labelledby="donate-tab"
                                        >
                                            <div className="text-center py-4">
                                                <img src="/assets/img/bgds/support.png" className="w30" alt="support us image" />
                                            </div>

                                            <div className="rectbordercolor p4 h600">
                                                <div><h2 className="display-6 greencolor varchefont">Donate</h2></div>

                                                <p className="display-5 mb-5 lorafont">
                                                    Thank you for your support. 
                                                    Book Bunker builds and manages low-cost compact libraries to support literacy in poor communities. 
                                                    Your donation will help build more libraries to serve underprivileged children. 
                                                </p>

                                                <div className="">
                                                    <div className="mt-2 text-dark row">
                                                
                                                        {/* <div className="col-lg">

                                                            <div className="">
                                                                <CircularProgressbar
                                                                    value={percentage}
                                                                    text={`${percentage}%`}
                                                                    className="w30"
                                                                    styles={buildStyles({
                                                                        // Rotation of path and trail, in number of turns (0-1)
                                                                        rotation: 0.25,

                                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                        strokeLinecap: 'butt',

                                                                        // Text size
                                                                        textSize: '16px',

                                                                        // How long animation takes to go from one percentage to another, in seconds
                                                                        pathTransitionDuration: 0.5,

                                                                        // Can specify path transition in more detail, or remove it entirely
                                                                        // pathTransition: 'none',

                                                                        // Colors
                                                                        pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
                                                                        textColor: '#f88',
                                                                        trailColor: '#d6d6d6',
                                                                        backgroundColor: '#3e98c7',
                                                                    })}
                                                                />
                                                            </div>
                                                        </div> */}

                                                        <div className="forms mx-auto">
                                                            <div className="d-flex lorafont">
                                                                <div className="custom-control custom-radio custom-control-inline w-50 justify-content-center">
                                                                    <input type="radio"
                                                                        className="custom-control-input"
                                                                        id="One Time"
                                                                        name="donationtype"
                                                                        value="One Time"
                                                                        checked={this.state.donationtype === "One Time"}
                                                                        onChange={this.handleRadio}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="One Time">One Time</label>
                                                                </div>

                                                                <div className="custom-control custom-radio custom-control-inline w-50 justify-content-center">
                                                                    <input type="radio"
                                                                        className="custom-control-input"
                                                                        id="Monthly"
                                                                        name="donationtype"
                                                                        value="Monthly"
                                                                        checked={this.state.donationtype === "Monthly"}
                                                                        onChange={this.handleRadio}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="Monthly">Monthly</label>
                                                                </div>
                                                            </div>

                                                            <div className="tab-pane fade show active my-3 lorafont" id="onetime" role="tabpanel" aria-labelledby="profile-tab">
                                                                <ul className="list-group list-group-horizontal mb-2">
                                                                    <li className={this.state.amount === 5000 ? "lstn outlinenone activebtn mr-2 w-50 nvitem" : "lstn outlinenone mr-2 w-50 nvitem"}><button onClick={() => {this.setState({amount:5000, amounttype: ""})}} className="outlinenone list-group-item list-group-item-action">₦5000</button></li>
                                                                    <li className={this.state.amount === 10000 ? "lstn outlinenone activebtn w-50 mr-2 nvitem" : "lstn outlinenone w-50 mr-2 nvitem"}><button onClick={() => {this.setState({amount:10000, amounttype: ""})}}  className="outlinenone list-group-item list-group-item-action">₦10000</button></li>
                                                                    <li className={this.state.amount === 15000 ? "lstn outlinenone activebtn w-50 nvitem" : "lstn outlinenone w-50 nvitem"}><button onClick={() => {this.setState({amount:15000, amounttype: ""})}}  className="outlinenone list-group-item list-group-item-action">₦15000</button></li>
                                                                </ul>

                                                                <ul className="list-group list-group-horizontal">
                                                                    <li className={this.state.amount === 20000 ? "lstn outlinenone activebtn mr-2 w-50 nvitem" : "lstn outlinenone mr-2 w-50 nvitem"}><button onClick={() => {this.setState({amount:20000, amounttype: ""})}} className="outlinenone list-group-item list-group-item-action">₦20000</button></li>
                                                                    <li className={this.state.amount === 25000 ? "lstn outlinenone activebtn mr-2 w-50 nvitem" : "lstn outlinenone mr-2 w-50 nvitem"}><button onClick={() => {this.setState({amount:25000, amounttype: ""})}} className="outlinenone list-group-item list-group-item-action">₦25000</button></li>
                                                                    <li className={this.state.amounttype === "other" ? "lstn outlinenone activebtn w-50 nvitem" : "lstn outlinenone w-50 nvitem"}><button onClick={() => {this.setState({amounttype: "other", amount: ""})}} className="outlinenone list-group-item list-group-item-action">Other</button></li>
                                                                </ul>
                                                            </div>

                                                            <div className="lorafont">
                                                                <input type="email" name="donoremail" value={this.state.donoremail} onChange={this.handleInput} className="formstyle"
                                                                    placeholder="Email"/>
                                                            </div>

                                                            <div className="mb-3 lorafont">
                                                                {
                                                                    this.state.amounttype === "other"
                                                                    &&
                                                                    <input min={100} type="number" name="amount" value={this.state.amount} onChange={this.handleInput} className="formstyle" placeholder="Amount"/>
                                                                }

                                                            </div>

                                                            <div className="text-danger small mb-1 lorafont">{(!this.state.donationtype && this.state.validate) && "Select donation type."}</div>
                                                            <div className="text-danger small mb-1 lorafont">{(!this.state.amount && this.state.validate) && "Select amount."}</div>
                                                            <div className="text-danger small mb-1 lorafont">{(!this.state.donoremail && this.state.validate) && "Enter your email address."}</div>

                                                            <div className="my-2 text-center varchefont">
                                                                <button onClick={this.handleNewDonor} className="btn btn-outline-secondary redcolor btnborder">Submit</button>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div 
                                            className={this.state.activetab === "JOIN" ? " tab-pane show active fade" : " tab-pane fade"}
                                            id="join" role="tabpanel" aria-labelledby="join-tab">

                                            <div className="text-center py-4">
                                                <img src="/assets/img/bgds/actnow.png" className="w15" alt="Act Now" />
                                            </div>

                                            <div className="rectbordercolor p4  h600">
                                                <div><h2 className="display-6 greencolor varchefont">Volunteer</h2></div>

                                                <p className="display-5 lorafont">
                                                    Our work is only possible due to our network of wonderful volunteers. 
                                                    Book Bunker relies on a diversity of skills to support our drive to foster literacy in impoverished communities. 
                                                    Please select for your areas of interest/expertise so that you are matched with the right team. 
                                                </p>

                                                <div className="mt-2 text-dark">
                                                <div className="text-center forms mx-auto">
                                                    <div className="row lorafont">

                                                        <div className="col-lg">
                                                            <input type="text" name="teamfullname" value={this.state.teamfullname} onChange={this.handleInput} className="formstyle"
                                                            placeholder="Full name"/>

                                                            <div className="text-danger small mb-1 text-left">{(!this.state.teamfullname && this.state.validate) && "Required."}</div>
                                                        </div>

                                                        <div className="col-lg">
                                                            <input type="email" name="teamemail" value={this.state.teamemail} onChange={this.handleInput} className="formstyle"
                                                            placeholder="Email"/>
                                                            <div className="text-danger small mb-1 text-left">{(!this.state.teamemail && this.state.validate) && "Required."}</div>
                                                        </div>
                                                    </div>

                                                    <div className="row lorafont">

                                                        <div className="col-lg">
                                                            <input type="phonenumber" name="teamphone" value={this.state.teamphone} onChange={this.handleInput} className="formstyle"
                                                            placeholder="Phone Number"/>
                                                            <div className="text-danger small mb-1 text-left">{(!this.state.teamphone && this.state.validate) && "Required."}</div>
                                                        </div>

                                                        <div className="col-lg">
                                                            <select className="formstyle" name="teamcategory" value={this.state.teamcategory} onChange={this.handleInput}>
                                                                <option value="">Select Team</option>
                                                                <option value="Book Drive">Book Drive</option>
                                                                <option value="Information Technology">Information Technology</option>
                                                                <option value="Librarian">Librarian</option>
                                                                <option value="Communications">Communications</option>
                                                                <option value="Corporate Relations">Corporate Relations</option>
                                                                <option value="Fund Raising">Fund Raising</option>
                                                                <option value="Building">Building</option>
                                                                <option value="Planning">Planning</option>
                                                            </select>
                                                            <div className="text-danger small mb-1 text-left">{(!this.state.teamcategory && this.state.validate) && "Required."}</div>
                                                        </div>
                                                    </div>

                                                    <div className="row lorafont">
                                                        <div className="col-lg">
                                                            <select onChange={this.print_state} className="country formstyle" id="country" name="teamcountry" value={this.state.teamcountry}>
                                                                <option value="">Select Country</option>
                                                            </select>
                                                            <div className="text-danger small mb-1 text-left">{(!this.state.teamcountry && this.state.validate) && "Required."}</div>
                                                        </div>

                                                        <div className="col-lg">
                                                            <select name="teamstate" value={this.state.teamstate} onChange={this.handleInput} className="formstyle" id="teamstate">
                                                                <option value="">Select State</option>
                                                            </select>
                                                            <div className="text-danger small mb-1 text-left">{(!this.state.teamstate && this.state.validate) && "Required."}</div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group textareainput lorafont">
                                                        <textarea
                                                            name="teamintroduction"
                                                            value={this.state.teamintroduction}
                                                            onChange={this.handleInput}
                                                            className="form-control"
                                                            id="introtext" rows="3"
                                                            placeholder="Introduce yourself: Tell us about your hobbies, your interests and current experience."
                                                        />
                                                        <div className="text-danger small mb-1 text-left">{(!this.state.teamintroduction && this.state.validate) && "Required."}</div>
                                                    </div>

                                                    <div className="my-2">
                                                        <button onClick={this.handleNewTeam} className="btn btn-outline-secondary redcolor btnborder varchefont">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div 
                                            className={this.state.activetab === "PARTNER" ? " tab-pane show active fade" : " tab-pane fade"}
                                            id="partner" role="tabpanel" aria-labelledby="partner-tab">
                                            <div className="text-center py-4">
                                                <img src="/assets/img/bgds/actnow.png" className="w15" alt="Act Now" />
                                            </div>

                                            <div className="rectbordercolor p4 h600">
                                                <div><h2 className="display-6 greencolor varchefont">Partner with us</h2></div>

                                                <p className="display-5 lorafont">
                                                    Every child deserves to read. Thanks to your partnership, we can make this possible. 
                                                    We work with –foundations, institutions, and brands - to achieve our vision of a world 
                                                    in which every child can read and has a chance of an education despite being born into poverty. 
                                                    Together, we can build a future for millions of children across Nigeria. 
                                                </p>

                                                <div className="mt-2 text-dark">

                                                <div className="text-center forms mx-auto">
                                                    <div className="row lorafont">

                                                        <div className="col">
                                                            <input type="text" name="partnerfullname" value={this.state.partnerfullname} onChange={this.handleInput} className="formstyle" placeholder="Name"/>
                                                            <div className="text-danger small mb-1 text-left">{(!this.state.partnerfullname && this.state.validate) && "Required."}</div>
                                                        </div>

                                                        <div className="col">
                                                            <input type="email" name="partneremail" value={this.state.partneremail} onChange={this.handleInput} className="formstyle" placeholder="Email"/>
                                                            <div className="text-danger small mb-1 text-left">{(!this.state.partneremail && this.state.validate) && "Required."}</div>
                                                        </div>

                                                    </div>

                                                    <div className="row lorafont">
                                                        <div className="col">
                                                            <input type="phonenumber" name="partnerphone" value={this.state.partnerphone} onChange={this.handleInput} className="formstyle" placeholder="Phone Number"/>
                                                            <div className="text-danger small mb-1 text-left">{(!this.state.partnerphone && this.state.validate) && "Required."}</div>
                                                        </div>

                                                        <div className="col">
                                                            <select className="formstyle" name="partnerspace" value={this.state.partnerspace} onChange={this.handleInput}>
                                                                <option value="">Partnership Space</option>
                                                                <option value="1">Funding</option>
                                                                <option value="2">Logistics</option>
                                                                <option value="3">Construction</option>
                                                            </select>
                                                            <div className="text-danger small mb-1 text-left">{(!this.state.partnerspace && this.state.validate) && "Required."}</div>
                                                        </div>
                                                    </div>

                                                    <div className="row lorafont">
                                                        <div className="col">
                                                            <select onChange={this.print_state} name="partnercountry" value={this.state.partnercountry} className="country formstyle" id="country">
                                                                <option value="">Select Country</option>
                                                            </select>
                                                            <div className="text-danger small mb-1 text-left">{(!this.state.partnercountry && this.state.validate) && "Required."}</div>
                                                        </div>

                                                        <div className="col">
                                                            <select name="partnerstate" value={this.state.partnerstate} onChange={this.handleInput} className="formstyle" id="partnerstate">
                                                                <option value="">Select State</option>
                                                            </select>
                                                            <div className="text-danger small mb-1 text-left">{(!this.state.partnerstate && this.state.validate) && "Required."}</div>
                                                        </div>
                                                    </div>

                                                    <div className="my-2">
                                                        <button onClick={this.handleNewPartner} className="btn btn-outline-secondary redcolor btnborder varchefont">Submit</button>
                                    
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div 
                                            className={this.state.activetab === "RECYCLE" ? " tab-pane show active fade" : " tab-pane fade"}
                                            id="recycle" role="tabpanel" aria-labelledby="recycle-tab">
                                            <div className="text-center py-4">
                                                <img src="/assets/img/bgds/actnow.png" className="w15" alt="Act Now" />
                                            </div>

                                            <div className="rectbordercolor p4 h600">
                                                <div><h2 className="display-6 greencolor varchefont">Recycle your books</h2></div>

                                                <p className="display-5 lorafont">
                                                    You remember those books you have alive in your memories but stashed in your cupboard collecting dust? 
                                                    Dig them out, from science books to novels, we want them. 
                                                    There are many children who cannot afford even an old textbook to support their school classes. 
                                                    Your dust mites are their jewels. Your donation will go a long way in supporting their education. 
                                                </p>

                                                <div className="display-5 lorafont">
                                                    <p>
                                                        Organizing a book drive is an easy way to scale up the size and impact of your contribution. 
                                                        It can be also a fun and fulfilling activity for friends and families. 
                                                        Please fill out the form below to enable us assist you in your project.
                                                    </p>
                                                </div>

                                                <div className="mt-2 text-dark">

                                                    <div className="text-center mx-auto forms lorafont">

                                                        <div className="row">
                                                            <div className="col">
                                                                <input type="text" name="recyclefullname" value={this.state.recyclefullname} onChange={this.handleInput} className="formstyle" placeholder="Name" required/>
                                                                <div className="text-danger small mb-1 text-left">{(!this.state.recyclefullname && this.state.validate) && "Required."}</div>
                                                            </div>

                                                            <div className="col">
                                                                <input type="email" name="recycleemail" value={this.state.recycleemail} onChange={this.handleInput} className="formstyle" placeholder="Email" required/>
                                                                <div className="text-danger small mb-1 text-left">{(!this.state.recycleemail && this.state.validate) && "Required."}</div>
                                                            </div>
                                                        </div>

                                                        <div className="">
                                                            <input type="text" name="recyclephone" value={this.state.recyclephone} onChange={this.handleInput} className="formstyle" placeholder="Phone Number" required/>
                                                            <div className="text-danger small mb-1 text-left">{(!this.state.recyclephone && this.state.validate) && "Required."}</div>
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col">
                                                                <select onChange={this.print_state} value={this.state.recyclecountry} className="country formstyle" id="country" name="recyclecountry">
                                                                    <option value="">Select Country</option>
                                                                </select>
                                                                <div className="text-danger small mb-1 text-left">{(!this.state.recyclecountry && this.state.validate) && "Required."}</div>
                                                            </div>

                                                            <div className="col">
                                                                <select name ="recyclestate" onChange={this.handleInput} value={this.state.recyclestate} className="formstyle" id="recyclestate">
                                                                    <option value="">Select State</option>
                                                                </select>
                                                                <div className="text-danger small mb-1 text-left">{(!this.state.recyclestate && this.state.validate) && "Required."}</div>
                                                            </div>
                                                        </div>

                                                        <div className="my-2">
                                                            <button onClick={this.handleNewRecycler} className="btn btn-outline-secondary redcolor btnborder varchefont">Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="page-section h-100" id="whatwedo">
                        <div className="container-fluid">

                            <div className="row">

                                <div className="col-lg whatwedo pagecontainer">
                                    <h2 className="text-center bluecolor varchefont w-50 mx-auto mb-4 pb-2" style={{borderBottom: "3px solid white"}}>
                                        What we do
                                    </h2>

                                    <div className="display-5 mb-3">
                                        <p className="mb-3 bluecolor varchefont">+Build community-sustainable libraries</p>
                                        <p className="lorafont">
                                            Our primary mission is to establish and nurture dynamic community-sustainable libraries. 
                                            We emphasize localized management of our libraries to foster community spirit, cooperation and ownership.
                                        </p>
                                    </div>

                                    <div className="display-5 mb-3">
                                        <p className="mb-3 bluecolor varchefont">+Provide educational classes</p>
                                        <p className="lorafont">
                                            In local communities, basic literacy is a challenge. 
                                            Thus, to ensure that all members of the community can fully utilize a library, 
                                            we aim to provide opportunities for free literacy classes, tutoring, and summer reading programs.
                                        </p>
                                    </div>

                                    <div className="display-5 mb-3">
                                        <p className="mb-3 bluecolor varchefont">+Redistribute learning materials</p>
                                        <p className="lorafont">
                                            During our book drives we often receive duplicates of the national board required textbooks. 
                                            Thus, we redistribute the additional textbooks we receive to schools in low income areas.
                                        </p>
                                    </div>

                                </div>

                                <div className="col-lg futureexp p-0">
                                    <div className="fexpansions milkgraybg pagecontainer">
                                        
                                        <h2 className="text-center greencolor varchefont">
                                            Future expansions
                                        </h2>

                                        <div className="mb-3 display-5">
                                            <p className="mb-3 redcolor varchefont">+Provide linkages to the larger educational communities.</p>
                                            <p className="lorafont">
                                                We aim to link our Book bunkers to an international library network for standards maintenance, 
                                                catalogue sharing, inclusion in online learning programs and remote employment opportunities.
                                            </p>
                                        </div>

                                        <div className="mb-3 display-5">
                                            <p className="mb-3 redcolor varchefont">+Support small business owners.</p>
                                            <p className="lorafont">
                                                We aim to work with local governments and sister NGOs to support small business owners through the 
                                                provision of online and in-person resources such as business plan reviews, market information and much more.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <section className="adaptedbg giveheight" id="uniquechallenges">
                        <div className="container-fluid px-0">

                            <div className="bg-secondary text-center pagecontainer display-6 onlyquote">
                                <p className="goldcolor lorafont">
                                    By design, our Book bunkers are adapted <br /> to the unique challenges faced in Nigeria 
                                </p>

                                <div><button className="btn btn-outline-secondary btnborder goldcolor varchefont">Learn How</button></div>
                            </div>

                        </div>
                    </section> */}

                </div>

                {/* <section className="page-section commonbg giveheight" id="quotes" style={{paddingTop: "10rem"}}>
                    <div className="container-fluid">

                        <div className="row quotes"> 

                            <div className="col-lg-5 display-4 text-center p-4 z1">
                                "Today a reader, <br /> tomorrow a leader.”
                                <div>– Margaret Fuller</div>
                            </div>

                            <div className="col-lg"></div>

                            <div className="greycircle"></div>

                            <div className="col-lg-5 display-4 text-center rightdiffquote p-4 z1 goldcolor">
                                “The more that you read, the more things you will know. The more you learn, the more places you’ll go.”
                                <div>— Dr. Seuss</div>
                            </div>

                            <div className="greyrect"></div>
                        </div>

                        <div className="display-4 text-center quotebottom">
                            “Once you learn to<br /> read, you will be <br /> forever free.” 
                            <div>— Frederick<br /> Douglass</div>
                        </div>

                    </div>
                </section> */}

                <section className="page-section pt-5 bg-white shadow giveheight" id="aboutus">

                    <div className="container-fluid">
                        <div className="text-center mb-5 varchefont"><h2>About Us</h2></div>

                        {/* <div className="bubbles1"></div>
                        <div className="bubbles1sub"></div>
                        <div className="bubbles2"></div>
                        <div className="bubbles2sub"></div> */}

                        <div className="row aboutpad">

                            <div className="abouttitles col-lg-2 mx-3 varchefont">
                                <div onClick={() => {this.toggleActiveText("vision", "Our Vision")}} className="ourvision abtt cp p-3 varchefont">Our Vision</div>
                                <div onClick={() => {this.toggleActiveText("mission", "Our Mission")}} className="ourmission abtt cp p-3 varchefont">Our Mission</div>
                                <div onClick={() => {this.toggleActiveText("focus", "Our Focus")}} className="ourfocus abtt cp p-3">Our Focus</div>
                                <div onClick={() => {this.toggleActiveText("values", "Our Values")}} className="ourvalues abtt cp p-3">Our Values</div>
                                <div onClick={() => {this.toggleActiveText("whatwedo", "What we do")}} className="whatwedo2 abtt cp p-3">What we do</div>
                                <div onClick={() => {this.toggleActiveText("story", "Our Story")}} className="ourstory abtt cp p-3">Our Story</div>
                            </div>

                            <div className="termdesc col-lg">
                                <p className="bluecolor varchefont">{this.state.activetexttitle}</p>
                                <div className="w80 termcontent lorafont">
                                    < this.state.activetext />
                                </div>
                            </div>
                        </div>
                        
                        {/* <div onClick={() => {this.toggleActiveText("whoweare", "Who We Are")}} className="whoweare1 text-center text-white">Who we are</div> */}
                    </div>
                </section>

                <section className="page-section animated fadeIn delay-0.5s py-5 commonbg" id="faq">
					<div className="container lorafont">
						<div className="row lorafont">
							<div className="col-lg-12 text-center">
								<h2 className="section-heading text-uppercase varchefont">FAQ</h2>
								<div className="accordion" id="faqAccordion">
									<div className="card">
										<div className="card-header" id="heading1">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
													How are the books sourced?
												</button>
											</h2>
										</div>
										<div id="collapse1" className="collapse" aria-labelledby="heading1" data-parent="#faqAccordion">
											<div className="card-body">
												<div className="text-left">
													<p className="text-muted" /><p className="MsoNormal" style={{marginLeft: '2rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level2 lfo1'}}>{/*[if !supportLists]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>○<span style={{fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '7pt', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span>{/*[endif]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>Book
                            bunker will run book drives commencing in 2021 </span></p><p className="MsoNormal" style={{marginLeft: '2rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level2 lfo1'}}>{/*[if !supportLists]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>○<span style={{fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '7pt', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span>{/*[endif]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>We
                            intend to put down permanent drop-off&nbsp;
													boxes in front of popular locations</span></p><p>
												</p><p className="MsoNormal" style={{marginLeft: '2rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level2 lfo1'}}>{/*[if !supportLists]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>○<span style={{fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '7pt', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span>{/*[endif]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>We
                            will also source books from international donors</span></p><p />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="accordion" id="faqAccordion">
									<div className="card">
										<div className="card-header" id="heading2">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
													What do you do with the books?
												</button>
											</h2>
										</div>
										<div id="collapse2" className="collapse" aria-labelledby="heading2" data-parent="#faqAccordion">
											<div className="card-body">
												<div className="text-left">
													    <p className="text-muted" />
                                                    <p className="MsoNormal" style={{marginLeft: '2rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level2 lfo1'}}>{/*[if !supportLists]*/}
                                                        <span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>○<span style={{fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '7pt', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            </span></span>{/*[endif]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>
                                                                Donated books are sorted, labelled and repacked. 
                                                        </span>
                                                    </p>
                                                    <p className="MsoNormal" style={{marginLeft: '2rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level2 lfo1'}}>{/*[if !supportLists]*/}
                                                        <span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>○<span style={{fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '7pt', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span></span>{/*[endif]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>
                                                        A selection will be sent to the Book bunker in local communites across the country, as needed.&nbsp;
                                                        </span>
                                                    </p>

                                                    <p className="MsoNormal" style={{marginLeft: '2rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level2 lfo1'}}>
                                                        <span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>
                                                            ○
                                                            <span style={{fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '7pt', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            </span>
                                                        </span>

                                                        <span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>
                                                            The others will be redistributed/donated to local schools, as needed. If you wish to become a partner, please click here.
                                                            <div>
                                                                <a className="my-3 ctabtn btn btn-outline-secondary redcolor btnborder varchefont" onClick={() => {this.switchTabs("RECYCLE")}}>
                                                                    Recycle your old books
                                                                </a>
                                                            </div>
                                                        </span>
                                                    </p>

												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="accordion" id="faqAccordion">
									<div className="card">
										<div className="card-header" id="heading3">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
													Do book bunkers update their catalogue?
												</button>
											</h2>
										</div>
										<div id="collapse3" className="collapse" aria-labelledby="heading3" data-parent="#faqAccordion">
											<div className="card-body">
												<div className="text-left">
													<p className="text-muted" /><p className="MsoNormal" style={{marginLeft: '2rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level2 lfo1'}}>{/*[if !supportLists]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>○<span style={{fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '7pt', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span>{/*[endif]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>Book
                            bunkers return their original catalogue in the first year.</span></p><p className="MsoNormal" style={{marginLeft: '2rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level2 lfo1'}}>{/*[if !supportLists]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>○<span style={{fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '7pt', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span>{/*[endif]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>
                            After which, in each following year, a selection of new titles will be added to the original catalogue.</span></p><p>
												</p><p className="MsoNormal" style={{marginLeft: '2rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level2 lfo1'}}>{/*[if !supportLists]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>○<span style={{fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '7pt', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span>{/*[endif]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>
                            Discounted book sales will begin to be held at the end of the 3rd year of operation and continue annually, to clear space for new catalogues.</span></p><p />

                            <p className="MsoNormal" style={{marginLeft: "2rem", textIndent: "-0.25in", lineHeight: "150%"}}><span style={{fontSize: "12pt", lineHeight: "150%", fontFamily: "Nunito"}}>○<span style={{fontVariantNumeric: "normal", fontVariantEastAsian: "normal", fontStretch: "normal", fontSize: "7pt", lineHeight: "normal", fontFamily: "&quotTimes New Roman&quot"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><span style={{fontSize: "12pt", lineHeight: "150%", fontFamily: "Nunito"}}>Alternatively, books maybe redistributed to new Book Bunker sites.</span></p>

												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="accordion" id="faqAccordion">
									<div className="card">
										<div className="card-header" id="heading4">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
													How are locations selected
												</button>
											</h2>
										</div>
										<div id="collapse4" className="collapse" aria-labelledby="heading4" data-parent="#faqAccordion">
											<div className="card-body">
												<div className="text-left">
													<p className="text-muted" /><p className="MsoNormal" style={{marginLeft: '2rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level2 lfo1'}}>{/*[if !supportLists]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>○<span style={{fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '7pt', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span>{/*[endif]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>Book
                            Bunker aims to link all communities to a well stocked library. Thus, a viable
                            location is any community.</span></p><p className="MsoNormal" style={{marginLeft: '2rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level2 lfo1'}}>{/*[if !supportLists]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>○<span style={{fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '7pt', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span>{/*[endif]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>However,
                            we intend to focus on poorer communities (rural and urban) with a large number
                            of youth and school-aged children</span></p><p className="MsoNormal" style={{marginLeft: '2rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level2 lfo1'}}>{/*[if !supportLists]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>○<span style={{fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '7pt', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span>{/*[endif]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>Other
                            factors which make it easier to enter a community are:</span></p><p className="MsoNormal" style={{marginLeft: '3.5rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level3 lfo1'}}>{/*[if !supportLists]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>■<span style={{fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '7pt', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span>{/*[endif]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>Community
                            expression of interest in having a library</span></p><p className="MsoNormal" style={{marginLeft: '3.5rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level3 lfo1'}}>{/*[if !supportLists]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>■<span style={{fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '7pt', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span>{/*[endif]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>Land
                            grant made available by the local government/ or a cheerful citizen for
                            establishment of the library</span></p><p className="MsoNormal" style={{marginLeft: '2rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level2 lfo1'}}>{/*[if !supportLists]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>○<span style={{fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '7pt', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span>{/*[endif]*/}<strong><span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>Nevertheless, we encourage citizens who
                              would love a book bunker in their community to contact us. </span></strong></p><p className="MsoNormal" style={{marginLeft: '2rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level2 lfo1'}}>
												</p><p className="MsoNormal" style={{marginLeft: '2rem', textIndent: '-.25in', lineHeight: '150%', msoList: 'l0 level2 lfo1'}}>{/*[if !supportLists]*/}<span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>○<span style={{fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: '7pt', lineHeight: 'normal', fontFamily: '"Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span></span>{/*[endif]*/}<strong><span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>Let’s light up Nigeria, one library at a
                              time!</span></strong></p><p />

                                                    <div>
                                                        <a className="my-3 ctabtn btn btn-outline-secondary redcolor btnborder varchefont" onClick={() => {this.switchTabs("PARTNER")}}>
                                                            Become a partner
                                                        </a>
                                                    </div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="accordion" id="faqAccordion">
									<div className="card">

										<div className="card-header" id="heading6">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
													What are the operation day and hours?
												</button>
											</h2>
										</div>

										<div id="collapse6" className="collapse" aria-labelledby="heading6" data-parent="#faqAccordion">
											<div className="card-body">
												<div className="text-left">
													<p className="text-muted" /><ol style={{marginTop: '0in'}} start={1} type={1}>
													<ol style={{marginTop: '0in', padding: 0}} start={1} type="a">
														<li className="MsoNormal" style={{lineHeight: '150%', msoList: 'l0 level2 lfo1'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>The library will be open from 9am -
                                6pm, Tuesday - Saturday </span></li>
														<li className="MsoNormal" style={{lineHeight: '150%', msoList: 'l0 level2 lfo1'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>Except on holidays</span></li>
													</ol>
												</ol><p />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="accordion" id="faqAccordion">
									<div className="card">
										<div className="card-header" id="heading7">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
													How may I get library cards?
												</button>
											</h2>
										</div>
										<div id="collapse7" className="collapse" aria-labelledby="heading7" data-parent="#faqAccordion">
											<div className="card-body">
												<div className="text-left">
													<p className="text-muted" /><ol style={{marginTop: '0in'}} start={1} type={1}>
													<ol style={{marginTop: '0in', padding: 0}} start={1} type="a">
														<li className="MsoNormal" style={{lineHeight: '150%', msoList: 'l0 level2 lfo1'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>Library cards can be paid for with <strong><em><span style={{color: '#434343'}}>cash or service&nbsp;</span></em></strong></span></li>
													</ol>
												</ol><p />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="accordion" id="faqAccordion">
									<div className="card">
										<div className="card-header" id="heading8">
											<h2 className="mb-0">
												<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
													How do the buses run?
												</button>
											</h2>
										</div>
										<div id="collapse8" className="collapse" aria-labelledby="heading8" data-parent="#faqAccordion">
											<div className="card-body">
												<div className="text-left">
													<p className="text-muted" />
                                                    <ol style={{marginTop: '0in', padding: "2rem"}} start={1} type={1}>
													<ol style={{marginTop: '0in', padding: 0}} start={1} type="a">
														<li className="MsoNormal" style={{lineHeight: '150%', msoList: 'l0 level2 lfo1'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>Buses will run a set published route
                                on the weekends</span></li>
														<ol style={{marginTop: '0in'}} start={1} type="i">
															<li className="MsoNormal" style={{lineHeight: '150%', msoList: 'l0 level3 lfo1'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>For the onset trip:</span></li>
															<ol style={{marginTop: '0in'}} start={1} type={1}>
																<li className="MsoNormal" style={{lineHeight: '150%', msoList: 'l0 level4 lfo1'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>Free</span></li>
																<li className="MsoNormal" style={{lineHeight: '150%', msoList: 'l0 level4 lfo1'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>Bus does not dismount except at the
                                    library.</span></li>
															</ol>
															<li className="MsoNormal" style={{lineHeight: '150%', msoList: 'l0 level3 lfo1'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>For the return trip:</span></li>
															<ol style={{marginTop: '0in'}} start={1} type={1}>
																<li className="MsoNormal" style={{lineHeight: '150%', msoList: 'l0 level4 lfo1'}}><span style={{fontSize: '12.0pt', lineHeight: '150%', fontFamily: 'Nunito', msoFareastFontFamily: 'Nunito', msoBidiFontFamily: 'Nunito'}}>&nbsp;a bus pass is required which is either
                                    a library card or a signed note from the librarian. </span></li>
															</ol>
														</ol>
													</ol>
												</ol>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</section>

                <section className="page-section animated fadeIn delay-0.5s commonbg pb-5" id="contact">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h3 className="section-heading text-uppercase varchefont">Contact Us</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="forms mx-auto">
                                <form id="contactForm" name="sentMessage" noValidate>
                                    <div className="row lorafont">

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input onChange={this.handleInput} name="supportname" value={this.state.supportname} className="form-control" id="name" type="text" placeholder="Your Name *" required data-validation-required-message="Please enter your name." />
                                                <p className="help-block text-danger" >{this.state.validate && !this.state.supportname && "Required." }</p>
                                            </div>
                                            <div className="form-group">
                                                <input onChange={this.handleInput} name="supportemail" value={this.state.supportemail} className="form-control" id="email" type="email" placeholder="Your Email *" required data-validation-required-message="Please enter your email address." />
                                                <p className="help-block text-danger" >{this.state.validate && !this.state.supportemail && "Required." }</p>
                                            </div>
                                            <div className="form-group">
                                                <input onChange={this.handleInput} name="supportphone" value={this.state.supportphone} className="form-control" id="phone" type="tel" placeholder="Your Phone *" required data-validation-required-message="Please enter your phone number." />
                                                <p className="help-block text-danger" >{this.state.validate && !this.state.supportphone && "Required." }</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <textarea onChange={this.handleInput} name="supportmessage" value={this.state.supportmessage} className="form-control" id="message" placeholder="Your Message *" required data-validation-required-message="Please enter a message." />
                                                <p className="help-block text-danger" >{this.state.validate && !this.state.supportmessage && "Required." }</p>
                                            </div>
                                        </div>
                                        <div className="clearfix" />
                                        <div className="col-lg-12 text-center">
                                            <div id="success" />
                                            <button id="sendMessageButton" className="btn btn-outline-secondary btnborder redcolor varchefont" type="button" onClick={this.handleSupport}>Send Message</button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
			</article>
		)
	}

	componentDidMount() {
        //document.body.scrollTop

        //store.get("activetab") ? this.setState({activetab: store.get("activetab")}) : this.setState({activetab: "DONATE"});

        this.print_country("country");
        //this.setCountDown();
    }
}

export {Home, Utilities};