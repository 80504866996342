import React from 'react';

let aboutus = {
    vision: function() {
        return (
            <p>To inspire the Nigerian youth to dream.</p>
        )
    },

    mission: function() {
        return (
            <p>To link all communities to a well stocked library in order to build a network of social learning hubs across the nation. 
            Book bunker foundation aims to achieve this mission by establishing and nurturing dynamic community-grounded libraries (book bunkers) across the nation.</p>
        )
    },

    focus: function() {
        return (
            <div>
                <p>Eradicating childhood illiteracy in Nigeria.</p>
                <p>Engendering wide-spread reading across the Nigerian society.</p>
                <p>Providing a socially and economically beneficial outlet for youth.</p>
                <p>Fostering community spirit and community cooperation.</p>
                <p>Offering a safe beautiful communal space.</p>
            </div>
        )
    },

    values: function() {
        return (
            <div>
                <p>Honesty: We are the custodians of trust for our donors and to our communities.</p>
                <p>Empathy: Respect for the people and communities we support.</p>
                <p>Teamwork: We leverage on expertise through collaboration.</p>
                <p>Creativity: We are seeking innovative solutions and ideas.</p>
                <p>Data: We believe in research-based intervention for measurable impact.</p>
            </div>
        )
    },

    whatwedo: function() {
        return (
            <div>
                <p>We build community-sustainable libraries. </p>
                <p>We provide educational classes.</p>
                <p>We redistribute learning materials.</p>
            </div>
        )
    },

    story: function() {
        return (
            <div>
                <p>Book bunker began with the plea of a young village girl called Oluchi. Oluchi loved to read and every Christmas she would eagerly await her cousins to visit from town because they brought back colourful textbooks and fat novels.  It was clear to all that Oluchi was bright and thirsting for knowledge. A few years later, the cousins noticed that Oluchi’s younger siblings and friends had begun to pick up her habits. One Christmas when they returned, they were met by a pack of giggling bright-eyed village girls all asking for stories and books. For those girls, their interest in reading had already marked a clear difference in their gaze, confidence, vocabulary, and reasoning. It became apparent that the few books the cousins had scrabbled for Oluchi alone would not be enough for all the girls. A cool night was shared on the balcony with the cousins reading out loud to a rapt audience.  Gazing at their young eager faces, it also became apparent that so much intelligence and potential was withering away in villages; and a better nation could arise from these beautiful minds, if given a chance. With this on their conscience, the cousins left with a resolution to return with a library for the village children. Thus, the Book Bunker project was hatched. </p>
            </div>
        )
    }
};

export default aboutus;