import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import {Home} from "./components/home";
import NotFound from "./components/notfound";

function App() {
    return (
        <Router>
            <Switch>
	    		<Route path='/' component={Home} />
				<Route path='' component={NotFound} />
			</Switch>
        </Router>
    )
}

ReactDOM.render(
	<App />,
	document.getElementById("root")
);