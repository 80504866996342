const axios = require('axios');

class HttpService {
    constructor() {}

    baseurl = "https://bookbunkerapi.herokuapp.com";

    postservice = (url, data) => {
        return axios.post(this.baseurl+url, data)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
    }
}

export default HttpService;